@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 157, 255, 0.3);
    background-color: #c6c6c6f0;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: #cacacaf0;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(128, 128, 128);
    border-radius: 50px;

    /* border: 1px solid #f1592a; */
}

html {
    scroll-behavior: smooth;
    font-family: "Open Sans", sans-serif;
    overflow-x: hidden;
}
.app {
    background-color: rgb(220, 220, 220);
    font-size: 15px;
}

.styledButton {
    font-weight: 600 !important;
    border: 1px solid #f1592a !important;
    color: #f1592a !important;
}

.styledButton:hover {
    background-color: #f1592a !important;
    color: white !important;
    border: 1px solid transparent !important;
    font-weight: 600;
}

.styledButton1{
    /* font-weight: 600 !important; */
    border: 1px solid #C5863F !important;
    /* color: #f1592a !important; */
}

.styledButton1:hover{
    background-color: #C5863F !important;
    color: white !important;
    border: 1px solid transparent !important;
    font-weight: 600;
}

.styledButtonPRIMERY {
    font-weight: 600 !important;
    background-color: #f1592a !important;
    border: 1px solid #f1592a !important;
    color: white !important;
}

.styledButtonPRIMERY :hover {
    font-weight: 600 !important;
    /* border: 1px solid #f1592a !important; */
    background-color: red;
    color: grey !important;
}

.styledButtonPRIMERY1{
    font-weight: 600 !important;
    background-color: #C5863F !important;
    border: 1px solid !important;
    color: white !important;

}

tr {
    border: none !important;
}

.dummy {
    color: #f1582ac2;
}

.placeholder{
    align-items: center;
}