.graves{
    font-size:  20px;
}

.assign_grave{
    font-size:  20px;
}

.sections{
    font-size: 20px;
}

.organizations{
    font-size: 20px;

}